<template>
  <div class="allcontens">
    <div class="content">
      <van-form @submit="submit" class="form">
        <van-field v-model="name" required name="name" label="持卡人" input-align="right" placeholder="请填写持卡人"
          :rules="[{ required: true, message: '请填写持卡人' }]" />
        <van-field v-model="bankId" @blur="bankCodeBlur" :formatter="formatter" required name="bankId" label="卡号"
          input-align="right" placeholder="请填写卡号" :rules="[{ required: true, message: '请填写卡号' }]" />
        <van-field placeholder="请选择开卡银行" :value="bankCode" input-align="right" name="bankCode" required readonly
          label="开卡银行" error :rules="[{ required: true, message: '请选择开卡银行' }]">
          <template #input>
            <van-dropdown-menu z-index="999">
              <van-dropdown-item v-model="bankCode" :options="bankCodes" ref="item" @change="getBankAgreementFun" />
            </van-dropdown-menu>
          </template>
        </van-field>
        <div class="unbind">
          <span @click="SendUnBindCode">解绑手机号</span>
        </div>
        <!-- <van-field v-model="bankBranch" name="bankBranch" input-align="right" label="开卡分行" placeholder="请填写开卡分行" :rules="[{ required: true, message: '请填写开卡分行' }]" /> -->
        <p class="tips margin-top-20">提示：银行卡一旦绑定， 不支持重新绑卡。</p>
        <p class="agreement" v-if="agreementInfo.authorizationAgreement">
          <van-checkbox v-model="agreement1"></van-checkbox> <span class="agreement-text">本人已充分阅读、理解并同意
            <a :href="agreementInfo.authorizationAgreement">《用户授权协议》</a>，授权<b style="color: #666;">中国银联</b>
            获取本人个人信息，包括<b style="color: #666;">姓名、证件类型、证件号码、手机号、所选银行卡号</b>
          </span>
        </p>
        <p class="agreement" v-if="agreementInfo.serviceAgreement">
          <van-checkbox v-model="agreement2"></van-checkbox> <span class="agreement-text">本人已阅读并同意
            <a :href="agreementInfo.serviceAgreement">《{{agreementInfo.serviceAgreementTitle}}》</a></span>
        </p>
        <div style="margin: 16px;" class="row justify-center">
          <van-button round type="info" size="small" class="margin-top-50 next-btn" native-type="submit">下一步3/4
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import storage from '@/utils/storage'
import { getSelectBankCode, bindBankCard, queryCardBin, getBankAgreement } from '@/api/sign'
export default {
  data() {
    return {
      bodyNo: '',
      name: '',
      bankId: '',
      bankCode: '',
      // bankBranch: '',
      bankCodes: [],
      mobile: '',
      agreement1: false, // 协议1
      agreement2: false, // 协议2
      agreementInfo: {}
    }
  },
  async created() {
    this.bodyNo = storage.getItem('bodyNo')
    this.mobile = this.$route.query.mobile
    const res = await getSelectBankCode()
    if (res.success) {
      const data = JSON.stringify(res.content).replace(/label/g, 'text')
      this.bankCodes = JSON.parse(data)
    }
  },
  methods: {
    // 获取服务协议
    getBankAgreementFun() {
      getBankAgreement({
        bankCode: this.bankCode
      }).then(res => {
        if (res.success) {
          console.log(res)
          this.agreementInfo = res.content
        }
      })
    },
    async SendUnBindCode() {
      this.$router.push({ path: '/sign/unbind_code', query: { mobile: this.mobile } })
    },
    formatter(value) {
      // 过滤输入的数字
      return value.replace(/[^\d]/g, '');
    },
    async bankCodeBlur(e) {
      const val = e.target._value
      if (!val) return

      const res = await queryCardBin({ bankCardNo: val })
      if (!res.success) {
        this.$toast(res.respMag)
        return
      }
      this.bankCode = res.content.bankCode
      this.getBankAgreementFun()
    },
    async submit(values) {
      if (!this.agreement1) {
        this.$toast.fail('请勾选《用户授权协议》！')
        return
      }
      if (this.agreementInfo.serviceAgreement) {
        if (!this.agreement2) {
          this.$toast.fail(`请勾选《${this.agreementInfo.serviceAgreementTitle}》！`)
          return
        }
      }
      const params = {
        bodyNo: this.bodyNo,
        bankCardNo: values.bankId,
        bankCode: values.bankCode,
        // bankBranchName: values.bankBranchName,
        bankCardHolder: values.name,
        cardCheck: 6,
        authorizationAgreement: 1,
        serviceAgreement: 1
      }
      const res = await bindBankCard(params)
      if (!res.success) {
        this.$toast.fail(res.respMag)
        return
      }
      res.success && this.$router.push({ path: '/sign/bank_cardverification', query: { applyOrderNo: res.content.applyOrderNo } })
    }
  }
}
</script>
<style scoped>
.borderbt {
  border-bottom: 1px solid #e4e7ed;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  font-size: 0.28rem;
}

.error {
  margin-left: 2rem;
  color: red;
  padding-top: 3px;
  /* padding-bottom: 0.05rem; */
  visibility: hidden;
}

.smsnext {
  margin: 1rem auto;
}

.unbind {
  display: flex;
  justify-content: flex-end;
  font-size: 0.3rem;
  margin-top: 0.2rem;
  color: #1989fa;
  margin-right: 0.2rem;
}

.bank-code {
  width: 70%;
  border: none;
  padding-left: 0.28rem;
  color: #757575;
  font-size: 0.28rem;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  background-image: url(../../assets/agreement/drop-down.svg);
  background-repeat: no-repeat;
  background-size: 0.3rem 0.3rem;
  background-position-x: 2.5rem;
  background-position-y: 0.06rem;
  /* background-position: 0rem 3rem; */
}

a {
  color: #0084fe;
  text-decoration: underline;
}

.form>>>.van-dropdown-menu__bar {
  height: auto;
  box-shadow: none;
}

.agreement {
  color: #999;
  padding: 0.1rem;
  font-size: 0.35rem;
  display: flex;
  justify-content: space-between;
  line-height: 0.6rem;
  align-items: flex-start;
}

.agreement-text {
  width: 75vw;
}
</style>
